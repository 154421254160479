// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-de-data-protection-jsx": () => import("./../../../src/pages/de/data-protection.jsx" /* webpackChunkName: "component---src-pages-de-data-protection-jsx" */),
  "component---src-pages-de-imprint-jsx": () => import("./../../../src/pages/de/imprint.jsx" /* webpackChunkName: "component---src-pages-de-imprint-jsx" */),
  "component---src-pages-de-jsx": () => import("./../../../src/pages/de.jsx" /* webpackChunkName: "component---src-pages-de-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

